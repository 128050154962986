<template>
  <div class="tiles-wrapper" v-if="users && users.length > 0">
    <DashboardSection>
      <template #top-right-button>
        <CustomButton
          @onClick="$router.push({ name: 'AddTeam' })"
          iconClass="plus"
          label="Add team members"
          color="grey"
          size="small"
          testId="addTeamMember"
        />
      </template>
      <template #content>
        <div v-for="(userList, index) of filteredUsers" :key="index" class="team-row">
          <span class="team-heading" v-if="userList.users.length > 0">{{ userList.title }}</span>
          <div class="users-row">
            <Tile v-for="(user, userIndex) in userList.users" v-bind:key="userIndex">
              <template #imageSection>
                <NameIcon :text="user.fullName" />
              </template>
              <template #title>
                {{ user.fullName }}
              </template>
              <template #desc>
                <p class="grey-value">{{ user.email }}</p>
              </template>
              <template #menu>
                <ul>
                  <li @click="$router.push({ name: 'EditTeam', params: { id: user.id } })">Edit</li>
                  <li v-if="checkUserEditOption(user.id)" @click="deleteMember(user.id)">Delete</li>
                </ul>
              </template>
            </Tile>
          </div>
        </div>
      </template>
    </DashboardSection>
  </div>
  <div class="no-documents" v-else>
    <NoResult title="You haven't added any team members yet">
      <CustomButton @click="$router.push({ name: 'AddTeam' })" label="Add team member" color="blue" />
    </NoResult>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { ActionTypes as UserActionTypes } from '@/store/users/actions'
import Tile from '@/components/atom/Tile.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import NameIcon from '@/components/atom/NameIcon.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'

export default defineComponent({
  name: 'Team',
  components: {
    Tile,
    CustomButton,
    DashboardSection,
    NameIcon,
    NoResult
  },
  setup() {
    const store = useStore()
    store.dispatch(UserActionTypes.GET_USERS)
    const users = computed(() => store.state.user.users)
    const filteredUsers = computed(() => {
      if (users.value && users.value.length > 0) {
        let teamsObj = {}
        const admin = users.value.filter((e) => e.role === 'ADMIN')
        const accManagers = users.value.filter((e) => e.role === 'ACC_MAN')
        const proManagers = users.value.filter((e) => e.role === 'PRO_MAN')
        const members = users.value.filter((e) => e.role === 'MEMBER')
        const superAdmins = users.value.filter((e) => e.role === 'SUPERADMIN')
        if (admin.length > 0) {
          teamsObj = Object.assign({}, teamsObj, { ADMIN: { title: 'Admin', users: admin } })
        }
        if (accManagers.length > 0) {
          teamsObj = Object.assign({}, teamsObj, { ACC_MAN: { title: 'Account manager', users: accManagers } })
        }
        if (proManagers.length > 0) {
          teamsObj = Object.assign({}, teamsObj, { PRO_MAN: { title: 'Project manager', users: proManagers } })
        }
        if (members.length > 0) {
          teamsObj = Object.assign({}, teamsObj, { MEMBER: { title: 'Team member', users: members } })
        }
        if (superAdmins.length > 0) {
          teamsObj = Object.assign({}, teamsObj, { MEMBER: { title: 'Super Admin', users: superAdmins } })
        }
        return teamsObj
      }
      return []
    })

    const checkUserEditOption = (userId: number) => {
      return store.state.auth.tokenContent?.userId !== userId
    }
    async function deleteMember(id: number) {
      await authApi.deleteUser(id).then(function () {
        const userIndex = users.value.findIndex((e: any) => e.id === id)
        if (userIndex !== -1) {
          users.value.splice(userIndex, 1)
        }
      })
    }
    return { filteredUsers, users, deleteMember, checkUserEditOption }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">
.tiles-wrapper
  // margin: 1rem
.add-button
  display: flex
  justify-content: flex-end
  & > *
    max-width: 12rem
.team-row
  display: flex
  flex-direction: column
  margin-bottom: 2.5rem
.users-row
  display: flex
  flex-wrap: wrap
  & > *
    margin-right: 1rem
    margin-bottom: 1rem
  .grey-value
    color: map-get($greyShades,greyShade60)
    font-size: 0.875rem
  @media( max-width: $md-breakpoint)
    flex-direction: column
.team-heading
  font-size: 1rem
  line-height: 1.25rem
  margin-bottom: 1rem
</style>
