
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { ActionTypes as UserActionTypes } from '@/store/users/actions'
import Tile from '@/components/atom/Tile.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import NameIcon from '@/components/atom/NameIcon.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'

export default defineComponent({
  name: 'Team',
  components: {
    Tile,
    CustomButton,
    DashboardSection,
    NameIcon,
    NoResult
  },
  setup() {
    const store = useStore()
    store.dispatch(UserActionTypes.GET_USERS)
    const users = computed(() => store.state.user.users)
    const filteredUsers = computed(() => {
      if (users.value && users.value.length > 0) {
        let teamsObj = {}
        const admin = users.value.filter((e) => e.role === 'ADMIN')
        const accManagers = users.value.filter((e) => e.role === 'ACC_MAN')
        const proManagers = users.value.filter((e) => e.role === 'PRO_MAN')
        const members = users.value.filter((e) => e.role === 'MEMBER')
        const superAdmins = users.value.filter((e) => e.role === 'SUPERADMIN')
        if (admin.length > 0) {
          teamsObj = Object.assign({}, teamsObj, { ADMIN: { title: 'Admin', users: admin } })
        }
        if (accManagers.length > 0) {
          teamsObj = Object.assign({}, teamsObj, { ACC_MAN: { title: 'Account manager', users: accManagers } })
        }
        if (proManagers.length > 0) {
          teamsObj = Object.assign({}, teamsObj, { PRO_MAN: { title: 'Project manager', users: proManagers } })
        }
        if (members.length > 0) {
          teamsObj = Object.assign({}, teamsObj, { MEMBER: { title: 'Team member', users: members } })
        }
        if (superAdmins.length > 0) {
          teamsObj = Object.assign({}, teamsObj, { MEMBER: { title: 'Super Admin', users: superAdmins } })
        }
        return teamsObj
      }
      return []
    })

    const checkUserEditOption = (userId: number) => {
      return store.state.auth.tokenContent?.userId !== userId
    }
    async function deleteMember(id: number) {
      await authApi.deleteUser(id).then(function () {
        const userIndex = users.value.findIndex((e: any) => e.id === id)
        if (userIndex !== -1) {
          users.value.splice(userIndex, 1)
        }
      })
    }
    return { filteredUsers, users, deleteMember, checkUserEditOption }
  }
})
